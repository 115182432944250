import React, { createContext, useState } from 'react';

// Create the context
export const AppContext = createContext();

// Context provider component
export const AppProvider = ({ children }) => {
  const [option, setOption] = useState("Decision Management System");

  return (
    <AppContext.Provider value={{option, setOption }}>
      {children}
    </AppContext.Provider>
  );
};
