import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Header from "./components/header";
import Home from "./Home/index";
import Blogs from "./Blogs/index";
import Footer from "./components/Footer";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Contact from "./Contact/index";
import PrivacyPolicy from "./PrivacyPolicy/index";
import CustomerService from "./Blogs/customer-service";
import ArtificialIntelligence from "./Blogs/artificial-intelligence";
import { AppProvider } from "./AppContext";

function App() {
  return (
    <AppProvider>
      <div className="App">
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="/blogs/5-key-elements-of-delivering-exceptional-customer-service"
              element={<CustomerService />}
            />
            <Route
              path="/blogs/the-role-of-artificial-intelligence-in-enhancing-customer-service-quality"
              element={<ArtificialIntelligence />}
            />
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
    </AppProvider>
  );
}

export default App;
