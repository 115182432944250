import React from "react";
import image1 from "../images/blogs/5-key-elements-of-delivering-exceptional-customer-service.jfif";
import image2 from "../images/blogs/Artificial-Intelligence-in-Enhancing-Customer-Service-Quality.jpg";
import Blogbanner from "./component/blogbanner";

const Blogs = () => {
  return (
    
    <div>
       <Blogbanner/>
      
      <div id="content">
        <div className="container">
          <ul id="bloglist" className="row text-start">
            <li className="col-md-6">
              <div className="bl_wrapper">
                <a href="/blogs/5-key-elements-of-delivering-exceptional-customer-service">
                  <img
                    src={image1}
                    alt="5 Key Elements of Delivering Exceptional Customer Service"
                  />
                  <div className="row text-start">
                    <div className="col-auto">
                      <div className="blogDate">
                        Mar <span>28</span> 2023
                      </div>
                    </div>
                    <div className="col">
                      <h2 className="blogTitle">
                        5 Key Elements of Delivering Exceptional Customer Service
                      </h2>
                      <p>
                        Customer satisfaction, brand loyalty, and retention are
                        essential aspects of customer service that can make or
                        break a business.
                      </p>
                      <span className="btn btn-outline-primary">
                        Read More
                      </span>
                    </div>
                  </div>
                </a>
              </div>
            </li>
            <li className="col-md-6">
              <div className="bl_wrapper">
                <a href="/blogs/the-role-of-artificial-intelligence-in-enhancing-customer-service-quality">
                  <img
                    src={image2}
                    alt="The Role of Artificial Intelligence in Enhancing Customer Service Quality"
                  />
                  <div className="row">
                    <div className="col-auto">
                      <div className="blogDate">
                        Mar <span>27</span> 2023
                      </div>
                    </div>
                    <div className="col">
                      <h2 className="blogTitle">
                        The Role of Artificial Intelligence in Enhancing Customer Service Quality
                      </h2>
                      <p>
                        Artificial Intelligence (AI) has become a buzzword in
                        today's world, transforming the way businesses operate.
                      </p>
                      <span className="btn btn-outline-primary">
                        Read More
                      </span>
                    </div>
                  </div>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
