import React, { useState, useContext } from 'react';
import loaderImg from './images/loader.svg';
import alertImg from './images/alert.svg';
import tickImg from './images/tick.svg';
import thumbUpImg from './images/thumb-up.svg';
import alert2Img from './images/alert2.svg';
import cxfirstLogo from './images/cxfirst-logo.svg';
import mailIcon from './images/mail-icon.svg';
import { Link, } from 'react-scroll';
import { AppContext } from '../AppContext'; // Import the context
import { Link as ReactDOMLink } from 'react-router-dom';
// import callIcon from './assets/images/call-icon.svg'; // Uncomment if needed

const Footer = () => {
  const [email, setEmail] = useState('');

  const {setOption } = useContext(AppContext);

  const handleEmailSubmit = async (email, successClass, errorClass) => {
    const data = {
      emailId: email,
    };

    try {
      const response = await fetch("https://demo.devcxfirst.com/contactDetails", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        // const responseData = await response.json();

        // Show success message
        const successMessage = document.querySelector(`.${successClass}`)
        successMessage.innerHTML = `<img src="/static/media/thumb-up.ff1b57af18aacab9d516384be7639467.svg" alt="Success"> We've got your email address, will get back to you shortly.`;
        successMessage.style.display = 'block';

        // Hide success message after 5 seconds
        setTimeout(() => {
          successMessage.style.display = 'none';
        }, 5000);
      } else {
        console.error("Error:", response.status);
        // showError(errorClass);
      }
    } catch (error) {
      console.error("Error:", error);
      // showError(errorClass);
    }
  };

  const proposal_mail3 = () => {
    const email = document.getElementById("proposal_mail3").value;
    handleEmailSubmit(email, 'proposal_mail3.msg-success', 'proposal_mail3err');
  };

  return (
    <footer id="sitefooter">
      <div className="container text-start">
        <div id="fcta">
          <h2>
            <span>You just need ONE APP </span><br />
            to make your team's life easy! <br />
            Just ONE APP.
          </h2>
          <div className="homedemo">
            <input
              id="proposal_mail3"
              type="text"
              placeholder="Your business email"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button className="btn btn-primary" onClick={proposal_mail3}>
              <span>Let's Empower</span>
              <div id="btn_set_demo_loader"></div>
              <div className="loader proposal_loader3_loader">
                <img src={loaderImg} alt=".." />
              </div>
              <div className="error proposal_loader3_error">
                <img src={alertImg} alt="!" />
              </div>
              <div className="success proposal_loader3_success">
                <img src={tickImg} alt="✓" />
              </div>
            </button>
            
              <div className="proposal_mail3 msg-success text-light" style={{ display: "none" }}>
                <img src={thumbUpImg} alt="Success" />
                We've got your email address, will get back to you shortly.
              </div>
          
          
              <div className="text-white proposal_mail3err msg-error" style={{ display: "none" }}>
                <img src={alert2Img} alt="error" />
                Please enter a valid email address!
              </div>
            
          </div>
        </div>
      </div>

      <div id="mainfooter">
        <div className="container text-start">
          <div className="row">
            <div className="col-md">
              <img src={cxfirstLogo} alt="CxFirst" />
              <div className="footcxinfo">One App enabling your employees <br /> deliver better CX</div>
              <p className="mt-4 mb-2">
                <a href="mailto:support@cxfirst.ai">
                  <img src={mailIcon} alt="Email" />
                  support@cxfirst.ai
                </a>
              </p>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3">
              <h5>CXFirst Platform</h5>
              <ul>
                <li className='li-cursor'><Link onClick={() => setOption("Decision Management System")} to='three_option' smooth={true} duration={500} offset={-50} data-scroll-nav="1">Decision Management System</Link></li>
                <li className='li-cursor'><Link onClick={() => setOption("Quality Management System")} to='three_option' smooth={true} duration={500} offset={-50} data-scroll-nav="1">Quality Management System</Link></li>
                <li className='li-cursor'><Link onClick={() => setOption("Training Management System")} to='three_option' smooth={true} duration={500} offset={-50} data-scroll-nav="1">Training Management System</Link></li>
              </ul>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3">
              <h5>Company</h5>
              <ul>
                <li><ReactDOMLink to="/privacy-policy">Privacy Policy</ReactDOMLink></li>
                <li><ReactDOMLink to="/contact">Contact</ReactDOMLink></li>
                <li><ReactDOMLink to="/blogs">Blogs</ReactDOMLink></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row credit align-items-center">
          <div className="col-md-auto order-md-2 text-end social-footer">
            <a href="https://www.linkedin.com/company/cxfirstai"><i className="fa fa-linkedin-square"></i></a>
          </div>
          <div className="col order-md-1 copyright text-start">
            Copyright &copy; <span>{new Date().getFullYear()}</span> CxFirst.ai Inc.. Powered By GLocal Travel Experiences Private Limited All Right Reserved | Made with love in India.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
